import Vue from 'vue'
import App from './App.vue'
import router from "@/router";

import "./assets/style/reset.scss"
import "./assets/style/common.scss"

import "./utils/rem"
// import './utils/application.js'

import store from "./store";

import Vconsole from 'vconsole';
if(window.location.search.indexOf('isTest')>0){
  const vConsole = new Vconsole()
  Vue.use(vConsole)
}

Vue.config.productionTip = false
import i18n from './locales'

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')