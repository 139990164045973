<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { projectInfo } from "@/api";
import { dotInit } from "@/utils";

export default {
  name: "App",
  created() {
    this.getProjectInfo();
  },
  methods: {
    async getProjectInfo() {
      let res = await projectInfo();
      this.appid = res.data.app_id;
      localStorage.setItem("projectInfo", JSON.stringify(res.data));
      dotInit();
      DotLog.pageReady("index");
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
