const setRem = () => {
  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  const htmlHeight = document.documentElement.clientHeight || document.body.clientHeight
// console.log(htmlWidth, htmlHeight)
let flag=3.75
if(htmlWidth>htmlHeight&&htmlWidth<=1024){
  // flag= htmlHeight/100
  // console.log(flag,"横屏")
  //小屏横屏
}else{
  //竖屏 手机端已375为标准
  flag= htmlWidth/100
  // flag=flag>=3.75?flag:3.75
  // console.log(flag,"竖屏")

}
  const htmlDom = document.getElementsByTagName('html')[0]
  htmlDom.style.fontSize = htmlWidth / flag + 'px'
}
setRem()
window.onresize = function () {
  setRem()
}