import api from './axios'
//eg 
export const goods = (params) => api.post(`/api/category/goods`, params)
export const login = (params) => api.post(`/api/user/login`,params)
export const facebookLogin = (params) => api.post(`/api/user/facebookLogin`,params)

export const createOrder = (params) => api.post(`/api/order/create`, params)
export const banner = (params) => api.get(`/api/banner/`,{params})
export const projectInfo = () => api.get(`/api/project/info`)

export const toContinue = (params) => api.post(`/api/user/toContinue`, params)
