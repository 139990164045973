import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [{
      path: "/",
      redirect: "/index"
    },
    {
      path: "/index",
      name: "index",
      component: () => import('@/views/Index.vue')
    }
    ,
    {
      path: "/iterm",
      name: "iterm",
      component: () => import('@/views/Goods/Iterm.vue')
    }
  ]
});