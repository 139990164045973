export function dotInit() {
    let account =
        localStorage.getItem("UserLogin") &&
        JSON.parse(localStorage.getItem("UserLogin")).account;
    let project_code =
        localStorage.getItem("projectInfo") &&
        JSON.parse(localStorage.getItem("projectInfo")).project_code;
    let url = location.href.split("/")[2] == "localhost:8080" ? process.env.VUE_APP_BASE_URL :'https://'+location.href.split("/")[2]

    DotLog.setInitAny(
        account,
        "payment-" + project_code,
        `${url}/api/event/up.gif`
    );
}