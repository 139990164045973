import Vue from 'vue'
import axios from 'axios'
// import router from "@/router/index";
import i18n from '../locales'
import {
  Toast
} from 'vant'

const validVue = Vue.extend({
  i18n
});
import VueRouter from "vue-router";
Vue.use(VueRouter);

// Handle navigation duplication for router push (Globally)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};

const api = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000
})

api.interceptors.request.use(
  config => {
    // config.headers['Content-type'] = 'application/json';
    let token = localStorage.getItem("token");

    if (token) {
      try {
        config.headers["Authorization"] = "Bearer " + token;
        /** 设备唯一标识符   */
        // config.headers["uuid"] = "";
      } catch (e) {
        console.log(e)
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    // console.log(response)
    if (response.status === 200 && response.data.code == 200) {
      return response.data
    }
    if (response.status === 200 && response.data.code == 401) {
      Toast(new validVue().$t("expire"))
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      location.reload()
      return;
    }
    if (response.status === 200 && response.data.code == 10401) {
      Toast(new validVue().$t("logError"))
      return;
    }
    //登录接口做的特殊处理，报500了，要报登录失败,
    if (response.status === 200 && response.data.code == 500 && response.data.message === "login error") {
      Toast(new validVue().$t("justlogError"))
      return false;
    }
    Toast(new validVue().$t("systemError"))
  },
  error => {
    console.log(error, "err info")
    return Promise.reject(error)
  }
)


export default api