import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from "@/store";

// import en from './en' // 以前的处理方法
// import zh from './zh'
// import de from './de'
// import ru from './ru'
// import pt from './pt'

import en from '@/translateData/en.json' //直接读取JSON
import zh from '@/translateData/zh.json'
import de from '@/translateData/de.json'
import ru from '@/translateData/ru.json'
import pt from '@/translateData/pt.json'

import es from '@/translateData/es.json' 
import fr from '@/translateData/fr.json'
import id from '@/translateData/id.json'
import it from '@/translateData/it.json'
import ja from '@/translateData/ja.json'

import ko from '@/translateData/ko.json' 
import ms from '@/translateData/ms.json'
import th from '@/translateData/th.json'
import vi from '@/translateData/vi.json'

// 引入element-ui的语言包
Vue.use(VueI18n)

const messages = {
  en,
  zh,
  de,
  ru,
  pt,
  es,
  fr,
  id,
  it,
  ja,
  ko,
  ms,
  th,
  vi
}
const i18n = new VueI18n({
  silentTranslationWarn: true,
  fallbackLocale: 'en',

  locale: store.state.locale,
  messages
})
// 配置element-ui的组件国际化
// ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n